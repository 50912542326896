import React from "react"
import { Button, Image } from "react-bootstrap"
import { Link } from "react-router-dom"

import ScrollTop from "../../components/ScrollTop"

import logo from "./logo.svg"
import cards from "./cards.png"
import ubiLogo from "./ubisoft-logo.svg"
import { ReactComponent as DownArrow } from "./arrow-bottom.svg"
import ubisoftInnovationLab from "../../assets/logos/UbisoftStrategicInnovationLab.svg"
import sorareLogo from "../../assets/logos/Sorare.svg"
import { ReactComponent as CrossIcon } from "../../assets/icons/cross.svg"
import ruleJupilerLogoImage from "../../assets/logos/JPL.svg"

import style from "./style.module.css"

const Rules = () => {
  return (
    <div className={style.ruleGrid}>
      <div className={style.ruleItem}>
        <div className={`${style.ruleHeader} ${style.header1}`}></div>
        <h3>Join the Jupiler Pro League Season</h3>
        <p>
          Create your pool of players for the season among the Pro League
          talents.
        </p>
      </div>

      <div className={style.ruleItem}>
        <div className={`${style.ruleHeader} ${style.header2}`}></div>
        <h3>Squad up for the weekend</h3>
        <p>
          Pick your dream team of 5 players for each match week. But choose
          wisely, every player can be used only once!
        </p>
      </div>

      <div className={style.ruleItem}>
        <div className={`${style.ruleHeader} ${style.header3}`}></div>
        <h3>Mondays get better</h3>
        <p>
          According to the selected players’ real-life performance, every Monday
          earn score and win Sorare collectible cards that can be used in-game.
        </p>
      </div>

      <div className={style.ruleItem}>
        <div className={`${style.ruleHeader} ${style.header4}`}></div>
        <h3>Who’s the best?</h3>
        <p>
          Add your friends and compare your scores on a dedicated leaderboard.
        </p>
      </div>

      <div className={style.ruleItem}>
        <div className={`${style.ruleHeader} ${style.header5}`}></div>
        <h3>Power Play</h3>
        <p>
          As you earn experience each week, you’ll get powerups that will grant
          more flexibility to design your perfect squad.
        </p>
      </div>

      <div className={style.ruleItem}>
        <div className={`${style.ruleHeader} ${style.header6}`}></div>
        <h3>It’s coming home</h3>
        <p>
          At the end of the season, the grand winner will be crowned and
          rewarded with special prizes!
        </p>
      </div>
    </div>
  )
}

const Partnership = () => {
  return (
    <div className={style.partnerList}>
      <div className={style.partnerItem}>
        <div className={style.logo}>
          <img src={sorareLogo} alt="Sorare" width="133" />
        </div>
        <div className={style.description}>
          Fantasy Football with blockchain
        </div>
        <Button
          variant="outline-primary"
          as="a"
          href="https://sorare.com/"
          target="_blank"
        >
          Visit
        </Button>
      </div>

      <div className={style.partnerItem}>
        <div className={style.logo}>
          <img
            src={ruleJupilerLogoImage}
            width="135"
            alt="Jupiler Pro League"
          />
        </div>
        <div className={style.description}>
          The Belgian championship is the first to join One Shot League
        </div>
        <Button
          variant="outline-primary"
          as="a"
          href="https://www.proleague.be/fr/jpl"
          target="_blank"
        >
          Visit
        </Button>
      </div>

      <div className={style.partnerItem}>
        <div className={style.logo}>
          <img
            src={ubisoftInnovationLab}
            height="40"
            alt="Ubisoft Strategic Innovation Lab"
          />
        </div>
        <div className={style.description}>
          <p>
            The mission of the Strategic Innovation Lab is to help Ubisoft
            anticipate the future.
          </p>
          <p>
            Both “think-tank” and “do-tank”, the Lab analyzes emerging
            technological, business and societal trends in order to identify
            opportunities for innovation. The team is also developing prototypes
            and pilot projects to explore certain avenues, in collaboration with
            internal and external partners.
          </p>
          <p>
            This exploratory role allows Ubisoft to get a head start in
            identifying innovative ideas and assessing their potential, before
            eventually integrating them into the company on a larger scale.
          </p>
        </div>
      </div>
    </div>
  )
}

const Landing = () => {
  return (
    <>
      <div className={style.background}>
        <div className={style.header}>
          <img src={logo} alt="oneshot league" />
        </div>
        <div className={style.mainContent}>
          <div className={`${style.desktopLeftContent} ${style.hideSm}`}>
            <div className={`${style.catchPhrase} ${style.lg}`}>
              This experience is over, <br /> thanks to all the fantasy football
              coaches who participated!
            </div>
            <div className={`${style.proposedBy} ${style.lg}`}>
              <p>A free to play game proposed by</p>
              <div className={style.ubisoftAndSorare}>
                <img
                  src={ubisoftInnovationLab}
                  alt="Ubisoft Strategic Innovation Lab"
                  height="24"
                />
                <CrossIcon className={style.x} width="15" height="15" />
                <img src={sorareLogo} alt="Sorare" width="133" />
              </div>
            </div>
          </div>
          <Image className={style.cards} src={cards} alt="sorare cards" fluid />
          <div className={`${style.catchPhrase} ${style.hideLg}`}>
            This experience is over, <br /> thanks to all the fantasy football
            coaches who participated!
          </div>
          <div className={`${style.proposedBy} ${style.hideLg}`}>
            <p>A free to play game proposed by</p>
            <div className={style.ubisoftAndSorare}>
              <img
                src={ubisoftInnovationLab}
                alt="Ubisoft Strategic Innovation Lab"
                height="24"
              />
              <CrossIcon className={style.x} width="15" height="15" />
              <img src={sorareLogo} alt="Sorare" width="133" />
            </div>
          </div>
        </div>
        <a className={style.downArrow} href="#rules">
          <DownArrow />
        </a>
      </div>
      <div className={style.innerShadow} id="rules">
        <div className={`centered-content ${style.paddingTop}`}>
          <div className={style.section}>
            <h2>How it works</h2>
            <Rules />
          </div>

          <div className={style.section}>
            <h2>Partnership</h2>
            <Partnership />
          </div>

          {/* <h2>Ubisoft Strategic Innovation Lab</h2>
          <p>TODO</p> */}
        </div>
      </div>
      <div className={style.footer}>
        <div className={`centered-content ${style.footContainer}`}>
          <img
            className={style.oneShotLogo}
            src={logo}
            alt="Oneshot League"
            height="30"
          />
          <img
            className={style.ubisoftLogo}
            src={ubiLogo}
            alt="Ubisoft"
            height="40"
          />
          <div className={style.footLinks}>
            <Link className={style.footerLink} to={"/rules"}>
              Game rules
            </Link>
            <a
              className={style.footerLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://discord.gg/TSjtHaM"
            >
              Discord
            </a>
            <a
              className={style.footerLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://legal.ubi.com/LegalInfoUbisoftEMEA/en-INTL"
            >
              Terms & conditions
            </a>
            <a
              className={style.footerLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://legal.ubi.com/privacypolicy/en-INTL"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      <ScrollTop bottomOffset={40} />
    </>
  )
}

export default Landing
