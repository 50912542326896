import React, { ReactNode } from "react"
import { Link } from "react-router-dom"

import style from "./style.module.css"

interface Props {
  title: string
  onClick?: () => void
  link?: { icon: ReactNode; to: string }
}

const SubHeader = ({ title, onClick, link }: Props) => {
  return (
    <div className={style.banner}>
      <div className={`${style.relative} centered-content`}>
        <div className={onClick ? style.centerText : ""}>{title}</div>
        {onClick && (
          <button className={style.backButton} onClick={onClick}>
            <svg
              stroke="currentColor"
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 1L1 6L6 11" strokeLinecap="round" />
            </svg>
            Back
          </button>
        )}
        {link && (
          <Link className={style.iconButton} to={link.to}>
            {link.icon}
          </Link>
        )}
      </div>
    </div>
  )
}

export default SubHeader
