import React, { HTMLAttributes } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

import { ReactComponent as ChevronRightIcon } from "../../assets/icons/chevron-right.svg"

import style from "./style.module.css"

interface Props extends HTMLAttributes<HTMLElement> {
  label: string
  to?: string
  href?: string
  target?: string
  rel?: string
  onClick?: () => void
}

const ArrowLink = ({ label, to, ...props }: Props) => {
  if (to) {
    return (
      <Button
        as={Link}
        to={to}
        variant="transparent"
        block
        className={style.button}
        {...props}
      >
        <span>{label}</span>
        <ChevronRightIcon />
      </Button>
    )
  }

  return (
    <Button variant="transparent" block className={style.button} {...props}>
      <span>{label}</span>
      <ChevronRightIcon />
    </Button>
  )
}

export default ArrowLink
