import React from "react"

import "./styles/app.css"
import "./styles/typography.css"

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import ErrorBoundary from "./ErrorBoundary"
import Rules from "./user/Rules"
import ScrollTop from "./components/ScrollTop"
import FAQ from "./user/FAQ"
import Landing from "./user/Landing"

const App = () => (
  <Router>
    <div className="app">
      <ErrorBoundary>
        <InnerApp />
      </ErrorBoundary>
    </div>
  </Router>
)

const InnerApp = () => (
  <>
    <Switch>
      <Route exact path={"/rules"}>
        <Rules />
        <ScrollTop />
      </Route>
      <Route exact path={"/faq"}>
        <FAQ />
        <ScrollTop />
      </Route>
      <Route exact path={"*"}>
        <Landing />
      </Route>
      <ScrollTop />
    </Switch>
  </>
)

export default App
