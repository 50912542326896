import React from "react"
import Image from "react-bootstrap/Image"
import Alert from "react-bootstrap/Alert"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Alert variant={"danger"}>
          <Alert.Heading>Something terrible happened...</Alert.Heading>
          <Image
            width={300}
            src={
              "https://cdn.ebaumsworld.com/mediaFiles/picture/2315435/84174527.gif"
            }
          />
          <p>{this.state.error && this.state.error.toString()}</p>
          <hr />
          <h4>Developer gibberish:</h4>
          <p>{this.state.errorInfo.componentStack}</p>
        </Alert>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
