import React from "react"
import { useHistory } from "react-router-dom"

import SubHeader from "../../components/Subheader"

import style from "./style.module.css"
import ArrowLink from "../../components/ArrowLink"

const Rules = () => {
  const history = useHistory()

  return (
    <>
      <SubHeader title="Game Rules" onClick={() => history.goBack()} />
      <div className="centered-content sub-header-spacing bottom-nav-spacing">
        <div className={style.section}>
          <h2>Season</h2>
          <p>
            The Season will include <strong>6 game weeks</strong> that will
            start on <strong>April the 30th, 2021</strong> you can catch up with
            the competition at anytime but the sooner you&apos;re in the faster
            you&apos;ll climb.
          </p>
        </div>

        <div className={style.section}>
          <h2>Squad</h2>
          <p>
            To create a team squad, you will need to select a soccer player for
            every role: 1 Goalkeeper, 1 defender, 1 midfielder, 1 forward, 1
            extra (the extra can’t be a goalkeeper).
          </p>
        </div>

        <div className={style.section}>
          <h2>Match days</h2>
          <p>
            Once your team is created just wait for the deadline of the fixture
            (8 PM every Friday).
          </p>
          <p>
            But beware,{" "}
            <strong>
              the players you selected are only available once for the season
            </strong>
            , at the end of every match days, each player selected in your team
            will be unavailable for the remaining!
          </p>
          <p>
            When the match days begin, the players you selected will play real
            life matches for the Jupiler Pro League, (1 match/player/match days)
            each action they make in the Jupiler matches will grant them a
            score. The sum of the scores of every members of your team will
            represent your score as a coach at the end of the match days.
          </p>
          <p>
            There will be 1 specific leaderboard per fixture but also a global
            leaderboard with the sums of the scores of each fixture so be
            consistent!
          </p>
        </div>

        <div className={style.section}>
          <h2>Earn Experience</h2>
          <p>
            Depending on various actions and your score at the end of a fixture
            you will receive some experience throughout the game, this
            experience let you level up and every 5 levels you get a special
            powerup for the survival league, use them wisely.
          </p>
        </div>

        <div className={style.section}>
          <h2>Earn Rewards</h2>
          <p>
            You will get rewarded depending on you position in the leaderboard,
            only the best coaches will get rewarded.
          </p>
          <p>
            In the event of a tie between two or more players, we have adopted
            the following criteria for ranking.
          </p>
          <strong>For a game week</strong>
          <ul>
            <li>1. Game week score</li>
            <li>2. Season score (sum of the past game weeks scores)</li>
            <li>
              3. Date of modification of your squad (the earlier, the better)
            </li>
            <li>4. Date of creation of your squad (the earlier, the better)</li>
            <li>
              5. If the above is not enough: your squad ID (random, changes from
              one game week to another)
            </li>
          </ul>

          <strong>For the season</strong>
          <ul>
            <li>1. Season score (sum of the game weeks scores)</li>
            <li>2. Performance in the last game week</li>
            <li>
              3. Date of modification of your last squad (the earlier, the
              better)
            </li>
            <li>
              4. Date of creation of your last squad (the earlier, the better)
            </li>
            <li>
              5. If the above is not enough: your squad ID (random, changes from
              one game week to another)
            </li>
          </ul>
        </div>

        <div className={style.section}>
          <h2>Create your pool</h2>
          <p>
            Before anything you must choose every soccer player for the season,{" "}
            <strong>
              beware, once you have chosen your soccer players and the season
              has started you can’t change the player pool!
            </strong>
            <br />
            You need to select enough players for 5 fixtures (25 total), try to
            get the best players and to anticipate the whole season. Keep in
            mind that each player can be used once !
          </p>
        </div>
        <ArrowLink label="FAQ" href={`/faq`} />
      </div>
    </>
  )
}

export default Rules
