import React, { useCallback, useEffect, useState } from "react"

import { CSSTransition, TransitionGroup } from "react-transition-group"

import style from "./style.module.css"

const THRESHOLD_PX = 2000

interface Props {
  scrollableTarget?: string
  bottomOffset?: number
}

const ScrollTop = ({ scrollableTarget, bottomOffset }: Props) => {
  const [showButton, setShowButton] = useState(false)
  const [
    scrollableElement,
    setScrollableElement,
  ] = useState<HTMLElement | null>(null)
  const scrollTop = () => {
    if (scrollableElement) {
      scrollableElement.scrollTo(scrollableElement.scrollLeft, 0)
    } else {
      window.scrollTo(window.scrollX, 0)
    }
  }
  const checkShowButton = useCallback(() => {
    const offset = scrollableElement
      ? scrollableElement.scrollTop
      : window.pageYOffset
    setShowButton(offset > THRESHOLD_PX)
  }, [scrollableElement])

  useEffect(() => {
    setScrollableElement(
      scrollableTarget ? document.getElementById(scrollableTarget) : null
    )
  }, [scrollableTarget])

  useEffect(() => {
    const element = scrollableElement || window

    element.addEventListener("scroll", checkShowButton)

    return () => element.removeEventListener("scroll", checkShowButton)
  }, [checkShowButton, scrollableElement])

  return (
    <TransitionGroup>
      {showButton && (
        <CSSTransition
          in={showButton}
          timeout={250}
          classNames={{
            enter: style.enter,
            exit: style.exit,
          }}
        >
          <button
            className={style.button}
            onClick={scrollTop}
            style={{ marginBottom: bottomOffset || 0 }}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3334 8.26262V22.0841H11.6667V8.26262L6.42265 13.5067L5.24414 12.3282L12.5001 5.07227L19.756 12.3282L18.5775 13.5067L13.3334 8.26262Z"
                fill="#D5CCEE"
              />
              <rect width="25" height="1.66667" fill="#D5CCEE" />
            </svg>
          </button>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

export default ScrollTop
