import React from "react"
import { Accordion, Card } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import SubHeader from "../../components/Subheader"

import style from "./style.module.css"

const FAQ = () => {
  const history = useHistory()

  return (
    <>
      <SubHeader title="FAQ" onClick={() => history.goBack()} />
      <div className="centered-content sub-header-spacing bottom-nav-spacing">
        <div className={style.section}>
          <h2>Requirements</h2>
          <p>
            a. <u>A computer running Chrome or Firefox, or a mobile device</u>
          </p>
          <p>
            You can access the One Shot League both on mobile devices and on
            computers, as long as you have installed a digital wallet on them.
          </p>
          <p>Wallets like Coinbase Wallet or Metamask, support Sorare.</p>
          <p>
            b. <u>A Sorare Account</u>
          </p>
          <p>
            You need to create an account on Sorare. Since the One Shot League
            is a partnership between Ubisoft’s Strategic Innovation Lab and
            Sorare (see below for details), the One Shot League will use your
            Sorare login to create your account.
          </p>
        </div>

        <div className={style.section}>
          <h2>About the One-Shot League</h2>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                What is the One Shot League?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    The One Shot League is a work in progress experience on the
                    Ethereum blockchain. On the One Shot LEAGUE, you will play
                    as a coach for a team of a soccer league. Prepare your best
                    teams for the One Shot League season as the soccer matches
                    in real life take place. But be prepared, you can only
                    select a player once during the season.
                  </p>
                  <p>
                    This experience is built as part of the Ubisoft
                    Entrepreneurs Lab research project in collaboration with
                    Sorare and the Jupiler Pro League. As this is a research
                    project the experience will likely change to meet the needs
                    of every actor of this partnership.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                I’m new. How do I start?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    First, you need to create an account on{" "}
                    <a href="https://sorare.com/">Sorare.com</a>. It will act as
                    your One Shot League login. The One Shot League isn’t
                    responsible of any data collected by Sorare, except on the
                    One Shot League website.
                  </p>
                  <p>
                    The first step of the One Shot League after registering is
                    the creation of your players pool for the current season. To
                    create a soccer team during the season you need to select
                    enough players from the One Shot League to have a consistent
                    pool. Choose wisely and be careful, as the pool can’t be
                    undone once the season has started.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
                What are the rules?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    The rules are quite simple, you join the One Shot League as
                    a coach for the season. You create your soccer players pool
                    once at the beginning of the season.
                  </p>
                  <p>
                    Once the season has started, for every game week you can
                    create a 5-player team to compete. It will be composed of 1
                    Goalkeeper / 1 Defender / 1 Midfielder / 1 Forward / 1 Extra
                    (the extra can be at any role but goalkeeper). Once the game
                    week has started, the players you selected will play games
                    in real life and depending on their performances they will
                    get a score. The score you have at the end of the week is
                    the sum of the scores of all the players you selected in the
                    team.
                  </p>
                  <p>
                    You also earn experience along the season which can lead to
                    obtain powerups. The purpose of these powerups is to give
                    you more flexibility to create teams by enabling you to
                    select a player twice, for example.
                  </p>
                  <p>
                    The experience is still a work in progress so these rules
                    might get adjusted.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                Can I change my pool?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Once the season has started and the pool is confirmed, you
                    cannot change it. However, if you forgot a soccer player you
                    absolutely need, you can access powerups that allow you to
                    add a few more players during the season.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
                How do I score points?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>
                    See the section 1.1 Player Score, in the item how do I score
                    points on{" "}
                    <a href="https://sorare.com/faq">https://sorare.com/faq</a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="5">
                What is a good score in the One Shot League experience for a
                soccer player?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p>
                    The worst score is 0 and the best is 100 with the average
                    being 50.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="6">
                How do I see my score?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p>
                    The score for each game week is updated live on the website,
                    so you can look for the game week on your dashboard. The
                    global leaderboard that sums up the score of every game week
                    is accessible at the bottom of the Dashboard.
                  </p>
                  <p>
                    The scores are updated in real time during the game week,
                    but nothing is set in stone, the scores can slightly change
                    up to 24 hours after the game week is over, so don’t be
                    surprised
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="7">
                How does the experience work ?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p>
                    Depending on various actions and your score at the end of a
                    fixture you will receive some experience throughout the
                    game, this experience let you level up and every 5 levels
                    you get a special powerup for the survival league.
                  </p>
                  <p>
                    <u>There are 3 ways of getting experience:</u>
                  </p>
                  <ul>
                    <li>
                      Locking a team: the 100 Xp bonus is available every week
                      onc
                    </li>
                    <li>
                      <p>
                        Depending on your score for a match day: the score bonus
                        is calculated this way
                      </p>
                      <p>
                        Score Xp Bonus = Goalkeeper score * Goalkeeper power
                        multiplier + Defender score * Defender power multiplier
                        + … + Extra player score * Extra player power
                        multiplicator
                      </p>
                      <p>
                        The power multiplier is linked to the best Sorare card
                        corresponding to the soccer player in your wallet
                      </p>
                    </li>
                    <li>
                      <p>
                        Climbing in the leaderboard: the Xp bonus is calculated
                        this way at the end of every match days
                      </p>
                      <p>Rank Xp Bonus = Log (rank difference) *100</p>{" "}
                      <p>
                        If the rank difference is 1 the Xp Bonus is the same as
                        if the rank difference was 2
                      </p>
                    </li>
                  </ul>
                  <p>
                    <u>You need experience to level up:</u>
                  </p>
                  <p>
                    The experience needed to get from level N-1 to level N is
                    calculated this way:
                  </p>
                  <p>
                    Needed Xp= 100 + Log1.04(N) (Rounded up to the superior
                    unit)
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="8">
                How does the power ups works ?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p>There are 3 different types of power ups:</p>
                  <ul>
                    <li>
                      Free Use: Grants you 1 more use for any soccer player (the
                      player doesn’t have to be in the pool), the number of uses
                      for 1 player is not limited and a player that have 0 use
                      left can be used again if you use this power up for him.
                    </li>
                    <li>
                      Save player: Choose 1 player selected in your team for the
                      current match day and at the end of the match days this
                      player won’t get the –1 use due to the end of the match
                      days.
                    </li>
                    <li>
                      Team Slot: Grant the user 1 more extra slot for the next
                      match days (does not stack, the maximum number of slots is
                      6)
                    </li>
                  </ul>
                  <p>
                    You earn power ups every 5 level you gain. The power up you
                    get is chosen semi-randomly according to theses
                    probabilities:
                  </p>
                  <ul>
                    <li>Free Use: 40% chance</li>
                    <li>Save Player: 55% chance</li>
                    <li>Team Slot: 5% chance</li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="9">
                Do I need to pay to be the best? Can I buy powerups or chests?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p>
                    You can’t pay to be the best. The One Shot League is built
                    around the idea that rewarding the coaches for their smart
                    decisions is more interesting than rewarding them for what
                    they bought. However, owning Sorare Cards will let you earn
                    more experience faster and then have flexibility earlier in
                    the season. This will surely give an edge on the smarter One
                    Shot League coaches.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="10">
                How do I get rewards?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p>
                    The best players of each game week and of the season will be
                    rewarded for their smart decision making. The best players
                    will earn Sorare Cards as a reward that can be used on
                    Sorare’s own experience or sold on a marketplace
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="11">
                Do I own the Sorare cards that I use on the One Shot League?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p>
                    The One Shot League lets you use every soccer player from
                    the pool even if you don’t own the corresponding common
                    Sorare card. You can use soccer players but using them
                    doesn’t create a common card in your Sorare wallet.
                  </p>
                  <p>
                    However, you do own the cards for any Rare, Super Rare and
                    Unique ones. Your One Shot League account will automatically
                    be linked to a Sorare account and then to your Sorare
                    Ethereum wallet. You have true ownership of any cards in
                    this wallet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="12">
                Can I play One Shot League without owning Sorare cards?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="12">
                <Card.Body>
                  <p>
                    The One Shot League is a completely free-to-play experience.
                    However, owning Sorare cards might give you more flexibility
                    in the way you think your team creation throughout the
                    season.
                  </p>
                  <p>
                    Still, the experience is balanced so the smart decisions are
                    more important than the money spent in the game.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="13">
                Can I use my own Sorare cards?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="13">
                <Card.Body>
                  <p>
                    The One Shot League is a side Sorare experience so if you
                    own any card corresponding to a soccer player in the One
                    Shot League pool, you can use it in a team and then get the
                    experience boost.
                  </p>
                  <p>
                    When you select a soccer player, only the best corresponding
                    card you own will be taken in consideration for the bonus
                    experience.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="14">
                What players can I use?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="14">
                <Card.Body>
                  <p>
                    The One Shot League pool is made of every soccer players
                    available for the current One Shot League season. For the
                    first season you will be able to select them from the
                    Jupiler Pro League.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="15">
                Why is the player pool limited to Jupiler Pro League?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="15">
                <Card.Body>
                  <p>
                    The One Shot League is a part of the Ubisoft Entrepreneurs
                    Lab project; therefore this experience is made as part of a
                    partnership between Sorare, Jupiler Pro League and the
                    Ubisoft Strategic Innovation Lab.
                  </p>
                  <p>
                    Since the experience is meant to take place in a precise
                    frame - a league, it was obvious to resort to the Jupiler
                    Pro League as a main frame for this first season of the One
                    Shot League, for its good relationship with Sorare and its
                    innovative exposure.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="16">
                How do I get Sorare Cards?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="16">
                <Card.Body>
                  <p>
                    You can get Sorare cards on the Sorare marketplace:{" "}
                    <a href="https://sorare.com/market/">
                      https://sorare.com/market/
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="17">
                What is a game week?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="17">
                <Card.Body>
                  <p>
                    The game week takes place from Friday afternoon to Monday
                    morning and it is the time period while the real matches are
                    played. Therefore, during a game week, the scores of your
                    team is updated in real time so you can follow the progress
                    of your team in the leaderboard.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="18">
                Can I join the league during the season?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="18">
                <Card.Body>
                  <p>
                    Once the season is started any coach can still join the
                    game. A lot of rewards are related to the game week
                    leaderboard so there is always a point in joining the One
                    Shot League. However, joining the season early will
                    obviously give you an advantage to get a good ranking in the
                    global season leaderboard.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>

        <div className={style.section}>
          <h2>About Sorare</h2>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                What is it?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Sorare is a global fantasy football game where you can play
                    and trade with officially licensed digital cards and earn
                    prizes every week. They are backed by a world-class team of
                    investors, supported by gaming giant Ubisoft and already
                    trusted by 100+ football clubs, with many more coming every
                    month.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                Does my score in the One Shot League affect my Sorare score or
                vice-versa?
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Not directly. The score you get for a One Shot League game
                    week doesn’t transfer to a Sorare game week or vice-versa.
                  </p>
                  <p>
                    But if you get Sorare cards as a reward in any experience
                    you can of course use your cards in the other one.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>

        <div className={style.section}>
          <h2>What is the Strategic Innovation Lab?</h2>
          <p>
            The Strategic Innovation Lab is the future-proof strategy branch of
            Ubisoft. It aims at anticipating Ubisoft’s future and helping teams
            prepare for it.
          </p>
          <p>
            It is composed of several teams, among which the Blockchain
            Initiative, the Entrepreneurs Lab and the Communication team are
            participating for this project.
          </p>
        </div>

        <div className={style.section}>
          <h2>What is the Ubisoft Entrepreneurs Lab?</h2>
          <p>
            The Ubisoft Entrepreneurs Lab supports innovative startups creating
            products and services that have the potential to transform the
            entertainment industry. Former participants in the Ubisoft
            Entrepreneurs Lab program include startups such as Mimesys
            (Belgium), acquired by MagicLeap in 2019, Hugging Face (US) which
            raised $15M in 2019, and Azarus (US), which recently partnered with
            Ubisoft for the Assassin Creed’s Valhalla reveal.
          </p>
        </div>

        {/* TODO Add Contact form and last section "Help! My problem wasn’t addressed here" */}
      </div>
    </>
  )
}

export default FAQ
